<template>
  <div>
    <div class="table-responsive">
      <table class="table horizontally-scrollable xl-width-table">
        <thead class="table-header">
          <tr>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.CURRENCY") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_OWNER_NAME") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.REAL_NAME") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.AMOUNT") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.BANK_CODE") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.EMAIL") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.PHONE") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.NOTES") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.APPROVER_NOTES") }}
            </td>
            <td class="header-row">
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.STATUS") }}
            </td>
          </tr>
        </thead>
        <tbody v-if="transactions.length !== 0 && showAmount">
          <tr v-for="(value, key) in transactions" :key="key">
            <td class="align-text-center">IDR</td>
            <td>
              <CInput
                :placeholder="
                  $t('DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_OWNER_NAME')
                "
                type="text"
                :value="value.account_owner_name"
                :disabled="disableResubmitField(value.status)"
                @input="
                  (newValue) => {
                    updateItem(
                      value.id.toString(),
                      'account_owner_name',
                      newValue
                    );
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.REAL_NAME')"
                type="text"
                :value="value.real_name"
                :disabled="true"
              >
              </CInput>
            </td>
            <td>
              <CInput
                class="align-text-right"
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.AMOUNT')"
                prepend="Rp. "
                type="text"
                pattern="[0-9]"
                onkeypress="return !(event.charCode == 46)"
                :is-valid="
                  disableResubmitField(value.status)
                    ? null
                    : invalidAmounts[value.id]
                "
                :value="
                  disableResubmitField(value.status)
                    ? formatAmount(value.amount)
                    : trimEditableAmount(value.amount)
                "
                :disabled="disableResubmitField(value.status)"
                @input="
                  (newValue) => {
                    updateItem(value.id.toString(), 'amount', newValue);
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.BANK_CODE')"
                type="text"
                :value="value.bank_code"
                :disabled="true"
              />
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER')"
                type="text"
                aria-valuemin="0"
                :value="value.account_number"
                :disabled="true"
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.EMAIL')"
                type="text"
                aria-valuemin="0"
                :value="value.email_recipient"
                :is-valid="
                  permissions.disbursementCreate ? validateEmail : null
                "
                :invalid-feedback="$t('VALIDATION_ERROR.EMAIL')"
                :disabled="disableResubmitField(value.status)"
                @input="
                  (newValue) => {
                    updateItem(
                      value.id.toString(),
                      'email_recipient',
                      newValue
                    );
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.PHONE')"
                type="text"
                aria-valuemin="0"
                :is-valid="
                  hasPermissionAccess(permissions.disbursementCreate) ? validatePhone : null
                "
                :invalid-feedback="$t('VALIDATION_ERROR.PHONE')"
                :value="value.phone_number"
                :disabled="disableResubmitField(value.status)"
                @input="
                  (newValue) => {
                    updateItem(value.id.toString(), 'phone_number', newValue);
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.NOTES')"
                type="text"
                aria-valuemin="0"
                :value="value.notes"
                :is-valid="
                  hasPermissionAccess(permissions.disbursementCreate) ? validateNotes : null
                "
                :invalid-feedback="$t('VALIDATION_ERROR.NOTES')"
                :disabled="disableResubmitField(value.status)"
                @input="
                  (newValue) => {
                    updateItem(value.id.toString(), 'notes', newValue);
                  }
                "
              >
              </CInput>
            </td>

            <td>
              <CInput
                type="text"
                aria-valuemin="0"
                placeholder="n/a"
                :value="value.approver_notes"
                :disabled="disableGeneralNotes(value.status)"
                @input="
                  (newValue) => {
                    updateItem(value.id.toString(), 'approver_notes', newValue);
                  }
                "
              >
              </CInput>
            </td>
            <td class="status-align">
              <CBadge
                :color="getDisbursementItemBadge(value.status)"
                class="status-badge font-bold table-status"
              >
                {{ getStatusCapitalized(value.status) }}
              </CBadge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <CRow class="mt-3">
        <CCol>
          <CButton
            color="durianprimary"
            class="float-right"
            v-element-access="permissions.disbursementCreate"
            v-if="isNeedRevision"
            @click="resubmitDisbursementByID"
          >
            <strong> {{ $t("DISBURSEMENTS.DETAILS.RESUBMIT_BUTTON") }}</strong>
          </CButton>
          <CButton
            color="durianprimary"
            class="float-right mr-2"
            v-element-access="permissions.disbursementApprove"
            v-if="isNeedApproval"
            @click="toggleApproveDisbursementModal"
          >
            <strong> {{ $t("DISBURSEMENTS.DETAILS.APPROVE_BUTTON") }}</strong>
          </CButton>
          <CButton
            v-element-access="permissions.disbursementApprove"
            class="float-right mr-2 cancel-button"
            v-if="isNeedApproval"
            @click="toggleRejectDisbursementModal"
          >
            <strong> {{ $t("DISBURSEMENTS.DETAILS.REJECT_BUTTON") }}</strong>
          </CButton>
        </CCol>
      </CRow>

      <reject-disbursement-modal
        :isModalOpen="isRejectDisbursementModalOpen"
        :toggleModal="toggleRejectDisbursementModal"
        :rejectDisbursement="rejectDisbursement"
      />
      <approve-disbursement-modal
        :isModalOpen="isApproveDisbursementModalOpen"
        :toggleModal="toggleApproveDisbursementModal"
        :approveDisbursement="approveDisbursement"
        :fees="fees"
        :totalAmount="totalAmount"
        :totalCountOfItems="totalValidItems"
      />
      <insufficient-balance-modal
        :isModalOpen="isInsufficientBalanceModalOpen"
        :toggleModal="toggleInsufficientBalanceModal"
      />
    </div>
  </div>
</template>

<script>
import {
  getDisbursementItemsByID,
  rejectDisbursmentByID,
  approveDisbursementByID,
  resubmitDisbursementByID,
} from "@/api/disbursement.api.js";
import InsufficientBalanceModal from "./modals/InsufficientBalanceModal.vue";
import ApproveDisbursementModal from "./modals/ApproveDisbursementModal.vue";
import RejectDisbursementModal from "./modals/RejectDisbursementModal.vue";
import { permissions } from '../../../../config';
const INVALID_STATUS = "invalid";
const VALID_STATUS = "valid";
export default {
  components: {
    InsufficientBalanceModal,
    ApproveDisbursementModal,
    RejectDisbursementModal,
  },
  name: "DisbursementDetailTable",
  data() {
    return {
      transactionsDetails: {},
      transactions: [],
      totalCountOfItems: 0,
      totalValidItems: 0,
      isRejectDisbursementModalOpen: false,
      isApproveDisbursementModalOpen: false,
      isInsufficientBalanceModalOpen: false,
      invalidAmounts: {},
      showAmount: true,
    };
  },
  props: {
    isNeedRevision: Boolean,
    isNeedApproval: Boolean,
    goToRevisionStatus: Function,
    goToApprovedStatus: Function,
    goToNeedApprovalStatus: Function,
    updateTotalAmount: Function,
    totalAmount: Number,
    fees: Number,
  },
  methods: {
    toggleRejectDisbursementModal() {
      this.isRejectDisbursementModalOpen = !this.isRejectDisbursementModalOpen;
    },
    toggleApproveDisbursementModal() {
      this.isApproveDisbursementModalOpen = !this
        .isApproveDisbursementModalOpen;
    },
    toggleInsufficientBalanceModal() {
      this.isInsufficientBalanceModalOpen = !this
        .isInsufficientBalanceModalOpen;
    },
    initializeInvalidAmountMap(itemsData) {
      const dataLength = itemsData.length;
      this.totalValidItems = 0;
      for (let i = 0; i < dataLength; i++) {
        this.invalidAmounts[itemsData[i].id] = true;
        if (itemsData[i].status === VALID_STATUS) {
          this.totalValidItems += 1;
        }
      }
    },
    async getDisbursementItems() {
      try {
        const response = await getDisbursementItemsByID(this.$route.params.id);
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.transactions = data.disbursement_batch_items;
          this.totalCountOfItems = data.count;
          this.initialiseTransactionMap(this.transactions);
          this.initializeInvalidAmountMap(this.transactions);
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_DISBURSEMENTS") +
            error.message
        );
      }
    },
    async resubmitDisbursementByID() {
      const payload = [];
      let totalAmount = 0;
      for (let key in this.transactionsDetails) {
        let {
          id,
          amount,
          account_owner_name,
          notes,
          email_recipient,
          phone_number,
        } = this.transactionsDetails[key];
        totalAmount +=
          this.transactionsDetails[key].status === VALID_STATUS
            ? parseInt(amount, 10)
            : 0;
        amount = amount.toString();
        const paylodObj = {
          id,
          amount,
          account_owner_name,
          notes,
          email_recipient,
          phone_number,
        };
        payload.push(paylodObj);
      }
      try {
        const response = await resubmitDisbursementByID(
          this.$route.params.id,
          payload
        );
        this.initializeInvalidAmountMap(this.transactions);
        const IsAmountInvalid = response.data.data.items_with_invalid_amount;
        if (IsAmountInvalid) {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.INVALID_AMOUNTS"));
          const invalidDataLength = IsAmountInvalid.length;
          this.showAmount = !this.showAmount;
          for (let i = 0; i < invalidDataLength; i++) {
            this.invalidAmounts[IsAmountInvalid[i]] = false;
          }
          this.showAmount = !this.showAmount;
          return;
        }
        this.goToNeedApprovalStatus();
        this.updateTotalAmount(totalAmount);
        this.initializeInvalidAmountMap(this.transactions);
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.RESUBMITTED_SUCCESSFULLY")
        );
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_RESUBMITTING_DISBURSEMENT")
        );
      }
    },
    updateItem(transactionID, feild, newValue) {
      this.transactionsDetails[transactionID][feild] = newValue;
    },
    initialiseTransactionMap(transaction) {
      for (let key in transaction) {
        this.transactionsDetails[transaction[key].id] = transaction[key];
      }
    },
    getNotes() {
      const notes = [];
      for (let key in this.transactionsDetails) {
        const id = key.toString();
        const note = this.transactionsDetails[id].approver_notes;
        const approverNotes = { id: id, note: note };
        notes.push(approverNotes);
      }
      return notes;
    },
    async approveDisbursement() {
      const notes = this.getNotes();
      try {
        const payload = { disbursement_items_notes: notes };
        const response = await approveDisbursementByID(
          this.$route.params.id,
          payload
        );
        const err = this.verifyHttpResponse(response);
        if (err) {
          this.showToaster(
            "Error approving disbursments. Please try after sometime"
          );
          return;
        }
        this.goToApprovedStatus();
        this.showSuccessToaster(
          "Disbursement batch item approved successfully"
        );
        this.toggleApproveDisbursementModal();
      } catch (error) {
        if (error.response.status === 403) {
          this.toggleApproveDisbursementModal();
          this.toggleInsufficientBalanceModal();
        } else {
          this.showToaster(
            "Error approving disbursements. Please try after sometime"
          );
        }
      }
    },
    async rejectDisbursement(generalNote) {
      const notes = this.getNotes();
      for (let key in this.transactionsDetails) {
        const id = key.toString();
        const note = this.transactionsDetails[id].approver_notes;
        const approverNotes = { id: id, note: note };
        notes.push(approverNotes);
      }
      try {
        const payload = {
          disbursement_items_notes: notes,
          general_note: generalNote,
        };
        const response = await rejectDisbursmentByID(
          this.$route.params.id,
          payload
        );
        const err = this.verifyHttpResponse(response);
        if (err) {
          this.showToaster(
            this.$t("DISBURSEMENTS.ERRORS.ERROR_REJECTING_DISBURSEMENT")
          );
          return;
        }
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.REJECTION_SUCEESS")
        );
        this.goToRevisionStatus();
        this.toggleRejectDisbursementModal();
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_REJECTING_DISBURSEMENT")
        );
      }
    },

    disableResubmitField(item_status) {
      return (
        !this.hasPermissionAccess(permissions.disbursementCreate) ||
        !this.isNeedRevision ||
        item_status === INVALID_STATUS
      );
    },
    disableGeneralNotes() {
      return !this.hasPermissionAccess(permissions.disbursementApprove) || !this.isNeedApproval;
    },
  },

  mounted() {
    this.getDisbursementItems();
  },
};
</script>

<style scoped>
.table-status {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}
</style>
