<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :hasButtonTopup="true" />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol col="2" md="2">
                <h5 class="mt-2">
                  <div class="text-muted table-labels">{{ $t("DISBURSEMENTS.DETAILS.NAME") }}</div>
                  <div class="table-value">{{ disbursement.name }}</div>
                </h5>
              </CCol>
              <CCol col="2" md="2">
                <h5 class="mt-2">
                  <div class="text-muted table-labels">{{ $t("DISBURSEMENTS.DETAILS.NUMBER") }}</div>
                  {{ disbursement.total_disbursements }}
                </h5>
              </CCol>
              <CCol col="2" md="2">
                <h5 class="mt-2">
                  <div
                    class="text-muted table-labels"
                  >{{ $t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT") }}</div>
                  Rp. {{ formatAmount(disbursement.total_amount) }}
                </h5>
              </CCol>
              <CCol col="2" md="2">
                <h5 class="mt-2">
                  <div class="text-muted table-labels">{{ $t("DISBURSEMENTS.DETAILS.STATUS") }}</div>
                  <CBadge
                    :color="getDisbursementBadge(disbursement.status)"
                    class="status-badge font-bold status-header"
                  >{{ getStatusCapitalized(disbursement.status) }}</CBadge>
                </h5>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0 pl-4">
            <CRow>
              <CCol sm="6" class="border-right p-4">
                <CRow class="padding-around">
                  <CCol>
                    <h6>{{ $t("DISBURSEMENTS.DETAILS.TYPE") }}</h6>
                  </CCol>
                  <CCol>
                    <div class="faded-value table-labels">{{ disbursement.type }}</div>
                  </CCol>
                </CRow>
                <CRow class="padding-around">
                  <CCol>
                    <h6>Disbursement ID</h6>
                  </CCol>
                  <CCol>
                    <div class="faded-value table-labels">{{ disbursement.id }}</div>
                  </CCol>
                </CRow>
                <CRow class="padding-around">
                  <CCol>
                    <h6>Sender/Uploader</h6>
                  </CCol>
                  <CCol>
                    <div class="table-labels">{{ disbursement.maker }}</div>
                  </CCol>
                </CRow>
                <CRow class="padding-around">
                  <CCol>
                    <h6>Approver</h6>
                  </CCol>
                  <CCol>
                    <div class="faded-value table-labels">{{ disbursement.approver }}</div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="6" class="p-4">
                <CRow class="padding-around">
                  <CCol>
                    <h6>Created At</h6>
                  </CCol>
                  <CCol>
                    <div class="faded-value table-labels">{{ formatDate(disbursement.created_at) }}</div>
                  </CCol>
                </CRow>
                <CRow class="padding-around">
                  <CCol>
                    <h6>{{ $t("DISBURSEMENTS.DETAILS.DESCRIPTION") }}</h6>
                  </CCol>
                  <CCol>
                    <div class="faded-value table-labels">{{ disbursement.description }}</div>
                  </CCol>
                </CRow>
                <CRow class="padding-around">
                  <CCol>
                    <h6>Mode</h6>
                  </CCol>
                  <CCol>
                    <div class="table-labels">{{ disbursement.is_live? "Live":"Sandbox" }}</div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow class="mt-2">
              <CCol xl="10" md="10" sm="10">
                <span class="detail-header">{{ $t("DISBURSEMENTS.DETAILS.HEADER") }}</span>
                <span class="text-muted mx-3">|</span>
                <span class="text-muted">
                  {{ $t("DISBURSEMENTS.DETAILS.TRANSACTIONS") }}:
                  <strong>{{ disbursement.total_disbursements }}</strong>
                </span>
                <span class="text-muted mx-3">|</span>
                <span class="text-muted mr-5">
                  {{ $t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT") }}: Rp.
                  <strong>{{ formatAmount(disbursement.total_amount) }}</strong>
                </span>
              </CCol>
              <CCol md="2" xl="2" sm="2">
                <div class="float-right export-button">
                  <CButton @click="exportDisbursementBatch" size="sm" color="lightborder">
                    <CIcon name="cil-cloud-download" />
                    {{ $t("COMMON.EXPORT") }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <DisbursementDetailTable
              :isNeedRevision="isNeedRevision"
              :isNeedApproval="isNeedApproval"
              :goToRevisionStatus="goToRevisionStatus"
              :goToApprovedStatus="goToApprovedStatus"
              :goToNeedApprovalStatus="goToNeedApprovalStatus"
              :updateTotalAmount="updateTotalAmount"
              :totalAmount="parseInt(disbursement.total_amount, 10)"
              :fees="parseInt(disbursement.fees, 10)"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import {
  getDisbursementByID,
  exportDisbursementBatchItems
} from "@/api/disbursement.api.js";
import DisbursementDetailTable from "./components/DisbursementDetailTable";
import BalanceCard from "./components/BalanceCard.vue";

const NEED_APPROVAL = "need_approval";
const NEED_REVISION = "need_revision";
const APPROVED = "approved";
const XLSX = ".xlsx";
const VALIDATION_STATUS = "valid";
const INVALID_STATUS = "invalid";
export default {
  components: {
    DisbursementDetailTable,
    BalanceCard
  },
  name: "DisbursementDetails",
  data() {
    return {
      isValidatedCollapsed: true,
      isInvalidatedCollapsed: false,
      filterModal: false,
      exportModal: false,
      visible: false,
      selected: null,
      totalRows: 0,
      disbursement: {},
      totalCount: 0,
      isNeedApproval: false,
      isNeedRevision: false
    };
  },
  methods: {
    async getDisbursementInfo() {
      try {
        const response = await getDisbursementByID(this.$route.params.id);
        this.disbursement = response.data.data;
        this.totalCount = response.data.data.count;
        if (this.disbursement.status === NEED_REVISION) {
          this.isNeedRevision = true;
        }
        if (this.disbursement.status === NEED_APPROVAL) {
          this.isNeedApproval = true;
        }
      } catch (error) {
        this.showToaster(this.$t("DISBURSEMENTS.ERROR_FETCHING_DISBURSEMENTS"));
      }
    },
    async initializeData() {
      await this.getDisbursementInfo();
    },
    async exportDisbursementBatch() {
      try {
        const params = { action: "export" };
        const response = await exportDisbursementBatchItems(
          this.$route.params.id,
          params
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const linkElement = document.createElement("a");
        linkElement.href = url;
        var fileName = this.getCSVName("disbursement_items") + XLSX;
        linkElement.setAttribute("download", fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        linkElement.remove();
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERROR_FETCHING_DISBURSEMENTS") + error.error
        );
      }
    },
    goToRevisionStatus() {
      this.disbursement.status = NEED_REVISION;
      this.isNeedRevision = true;
      this.isNeedApproval = false;
    },
    goToApprovedStatus() {
      this.disbursement.status = APPROVED;
      this.isNeedRevision = false;
      this.isNeedApproval = false;
    },
    updateTotalAmount(totalAmount) {
      this.disbursement.total_amount = totalAmount;
    },
    goToNeedApprovalStatus() {
      this.disbursement.status = NEED_APPROVAL;
      this.isNeedRevision = false;
      this.isNeedApproval = true;
    }
  },
  mounted() {
    this.initializeData();
  }
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid black;
}
.table-labels {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.table-value {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.padding-around {
  padding: 3px;
  line-height: 17px;
}
.faded-value {
  color: #4e4f52;
}
.status-header {
  font-size: 1.09375rem;
}
.export-button {
  position: relative;
  bottom: 5px;
}
.detail-header {
  font-size: 18px;
  font-weight: bold;
}
</style>