<template>
  <CModal :centered="true" :show="isModalOpen">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center mt-2 space-around">
      <div class="header">
        {{ $t("DISBURSEMENTS.REJECT_DISBURSEMENT_MODAL.HEADER") }}
      </div>
      <div class="text-muted">
        {{ $t("DISBURSEMENTS.REJECT_DISBURSEMENT_MODAL.MESSAGE") }}
      </div>
      <textarea type="text" class="lg-input" v-model="generalNote" />
      <div class="float-right">
        <CButton @click="toggleModal" class="cancel-button"
          ><strong>{{
            $t("DISBURSEMENTS.REJECT_DISBURSEMENT_MODAL.CANCEL")
          }}</strong></CButton
        >
        <CButton
          color="durianprimary"
          @click="rejectDisbursement(generalNote)"
          >{{ $t("DISBURSEMENTS.REJECT_DISBURSEMENT_MODAL.REJECT") }}</CButton
        >
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    rejectDisbursement: Function,
    toggleModal: Function,
  },
  data() {
    return {
      generalNote: "",
    };
  },
};
</script>