<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center mt-2 space-around">
      <div class="header">
        {{ $t("DISBURSEMENTS.INSUFFICIENT_FUNDS.HEADER") }}
      </div>
      <div class="text-muted padding-bottom">
        {{ $t("DISBURSEMENTS.INSUFFICIENT_FUNDS.MESSAGE_1") }}
      </div>
      <div class="float-right">
        <CButton @click="toggleModal" class="cancel-button"
          ><strong>{{
            $t("DISBURSEMENTS.INSUFFICIENT_FUNDS.CANCEL")
          }}</strong></CButton
        >
        <CButton color="durianprimary" @click="goTo('/disbursements/topup')">{{
          $t("DISBURSEMENTS.INSUFFICIENT_FUNDS.TOP_UP")
        }}</CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  name: "FileEmptyMessageModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
  },
};
</script>
