<template>
  <CModal :centered="true" :show="isModalOpen">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center mt-2 space-around">
      <div class="header">
        {{ $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.HEADER") }}
      </div>
      <div class="text-muted padding-bottom">
        {{ $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.MESSAGE_1") }} <br />
        {{ $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.MESSAGE_2") }}
      </div>
      <table class="p-3 d-flex flex-row justify-content-between bottom-border">
        <td>
          <tr>
            {{
              $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.AMOUNT")
            }}
          </tr>
          <tr>
            {{
              $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.TRANSFER_FEE")
            }}
          </tr>
        </td>
        <td>
          <tr class="align-text-right">
            <strong>Rp. {{ formatAmount(totalAmount) }}</strong>
          </tr>
          <tr class="align-text-right">
            <strong> Rp. {{ formatAmount(fees) }} </strong>
          </tr>
        </td>
      </table>
      <table class="p-3 d-flex flex-row justify-content-between">
        <td>
          <tr>
            {{
              $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.TOTAL_AMOUNT")
            }}
          </tr>
        </td>
        <td>
          <tr class="align-text-right">
            <strong
              >Rp.
              {{
                formatAmount(
                  parseInt(totalAmount, 10) + parseInt(fees, 10)
                )
              }}</strong
            >
          </tr>
        </td>
      </table>
      <hr />
      <wallet class="float-left margin-right-top" />
      <div class="float-left">
        <small class="text-muted d-block label-font">
          {{ $t("DISBURSEMENTS.LABELS.YOUR_BALANCE") }}
        </small>
        <strong class="balance"> Rp. {{ formatAmount(balance) }}</strong>
      </div>
      <div class="float-right">
        <CButton @click="toggleModal()" class="cancel-button"
          ><strong>{{
            $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.CANCEL")
          }}</strong></CButton
        >
        <CButton color="durianprimary" @click="approveDisbursement()">{{
          $t("DISBURSEMENTS.APPROVE_DISBURSMENT_MODAL.APPROVE")
        }}</CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
import { getBalance } from "@/api/disbursement.api.js";
import Wallet from "../Wallet";

export default {
  name: "ApproveDisbursementModal",
  components: { Wallet },
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    approveDisbursement: Function,
    totalCountOfItems: Number,
    fees: Number,
    totalAmount: Number,
  },
  data() {
    return {
      balance: 0, //inital value
    };
  },
  methods: {
    async getUserBalance() {
      try {
        const response = await getBalance();
        if (!this.verifyHttpResponse(response)) {
          this.balance = response.data.data.balance;
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BALANCE")
        );
      }
    },
  },
  mounted() {
    this.getUserBalance();
  },
};
</script>
<style lang="scss" scoped>
.margin-right-top {
  margin-right: 10.67px;
  margin-top: 5px;
}
.label-font {
  font-size: 12px;
  line-height: 15px;
}
</style>
